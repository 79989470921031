.mobile__nav_background{
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    position:  absolute !important;
    top: -2rem;
    right: 0rem;
    z-index: 1000;
    transition:  transform .8s ease-out;
    /* transform: scale(80); */
}

.mobile__nav_button{
    background-color: white;
    height: 4rem;
    width: 4rem;
    position: absolute !important;
    right: 0rem;
    top: -2rem;
    border-radius: 50%;
    z-index: 2000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;

}

.mobile__nav_checkbox{
    display: none !important;
}

.mobile__nav_navigation{
    height: 95vh;
    position: fixed ;
    top:0;
    right:0;
    z-index: 1500;
    opacity: 0;
    width:0px;
    /* transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55) ;  */
    transition: all 0.5s;
    border-radius: 0px 0px 20px 20px;
    background: #F6EBFF;
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.15);
}

.mobile__nav_list{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    list-style: none;
    text-align: center;
    width:100%
}

.mobile__nav__item{
    margin: 16px;
    
}

.mobile__nav__link:link, .mobile__nav__link:visited {
    display: inline-block;
    font-size: 1.5rem !important;
    font-weight: 700 !important;
    color: #9531F3 !important;
    padding: .6rem;
    text-decoration: none !important;
    /* text-transform: uppercase !important; */
    background-image: linear-gradient(120deg, transparent 0%, transparent 50%, white 50% ) !important;
    background-size: 250%;
    transition: all 0.4s;
}

.mobile__nav__link:hover, .mobile__nav__link:active {
     background-position: 100%;
     color: #8431FC !important;
     transform: translateX(1rem);
}


.mobile__nav_checkbox:checked ~ .mobile__nav_background {
    transform: scale(80);
}
.mobile__nav_checkbox:checked ~ .mobile__nav_navigation {
    opacity: 1;
    width: 100%;
}

.mobile__nav_icon{
    position: relative !important;
    margin-top: 2rem;
    margin-left: 1.5rem;
}

.mobile__nav_icon,  .mobile__nav_icon::before, .mobile__nav_icon::after {

    width: 1rem !important;
    height: 3px !important;
    background-color: #8431FC  !important;
    display: inline-block !important;

}

.mobile__nav_icon::before, .mobile__nav_icon::after {

   content: "" !important;
   position: absolute !important;
   transition: all 0.2s;


}

.mobile__nav_icon::before{
    top: -0.4rem
}
    

 .mobile__nav_icon::after {
     top: 0.4rem
 }

 [type='checkbox']:checked + label:before, [type='checkbox']:checked + label:after, [type='checkbox']:not(:checked) + label:before{
    display: none;
 }


 .mobile__nav_button:hover .mobile__nav_icon::before {
     top: -0.5rem;
 }

 .mobile__nav_button:hover .mobile__nav_icon::after {
     top: 0.5rem;
 }


 
 .mobile__nav_checkbox:checked + .mobile__nav_button .mobile__nav_icon {
   background-color: transparent !important;
}
.mobile__nav_checkbox:checked + .mobile__nav_button .mobile__nav_icon::before {
    top: 0;
    transform: rotate(135deg);
}
.mobile__nav_checkbox:checked + .mobile__nav_button .mobile__nav_icon::after {
    top: 0;
    transform: rotate(-135deg);
}

.get_started_btn{
border-radius: 40px;
background: linear-gradient(152deg, #8431FC 0%, #A632EB 100%);
width: 80%;
margin:24px auto;
}

.log_in_btn{
    border-radius: 44px;
    background: #FEFEFE;
    box-shadow: 0px 0px 20px 0px rgba(143, 152, 169, 0.20);
    text-shadow: 0px 1px 15px rgba(0, 0, 0, 0.25);
    font-size: 16px;
    margin:24px auto;
    width: 80%;
    font-weight: 800;
    color:#8431FC;
    /* background: linear-gradient(152deg, #8431FC 0%, #A632EB 100%);
    background-clip: text; */
    border: 1px solid #8431FC;
    /* -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}


@media (max-width: 350px) {
    cambri-padding {
       padding: 0.75rem !important;
    }
  }

  @keyframes blink {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }

  .blinking-text {
    animation: blink 1s infinite;
  }
