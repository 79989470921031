@import '~normalize.css';
@import "main.css";

:root {
  --c-black: #333;
  --c-red: #b03060;
  --c-teal: #008080;

  --c-gray: #919191;
  --c-gray-light: #b0b0b0;
  --c-gray-dark: #5c5c5c;

  --c-divider: #e5e5e5;

  --font-family: 'Avenir', 'Work Sans', sans-serif;

  --shadow-depth-3: 0 6px 24px rgba(0, 0, 0, 0.12), 0 2px 6px rgba(0, 0, 0, 0.08);
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea {
  font-family: 'Avenir','Work Sans', sans-serif;
}

.item-enter {
  opacity: 0;
}

.item-enter-active {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.item-exit {
  opacity: 1;
}

.item-exit-active {
  opacity: 0;
  transition: opacity 0.3s ease-in;
}
@keyframes marquee {
  0% {
      transform: translateX(0%);
  }
  100% {
      transform: translateX(-100%);
  }
}

.marquee-container {
  display: flex;
  flex-direction: row;
}

.marquee-content {
  animation: marquee 7.5s linear infinite;
  display: flex;
  white-space: nowrap;
}

.marquee-item {
color: #1976d2;
margin-right: 4rem;
font-size: large;
}

.hero-text {
  stroke: white;
  stroke-width: 3;
  animation: textAnimate 5s infinite alternate;
}

@keyframes textAnimate {
  0% {
    stroke-dasharray: 0 100%;
    stroke-dashoffset: 100%;
    fill: hsl(0, 0%, 5%);
  }
  50% {
    stroke-dasharray: 50% 0;
    stroke-dashoffset: 0%;
    fill: hsl(0, 0%, 2%);
  }
  100% {
    stroke-dasharray: 0 100%;
    stroke-dashoffset: 100%;
    fill: hsla(189, 68%, 75%, 0%);
  }
}


@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-custom {
  animation: fadeInUp 0.5s ease-out;
}
